import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vendor Onboarding - Ensuring Ongoing Success" />
            <div className="ensure-ongoing-success-page">
                <Container className="container-1">
                    <Row className="center header-text-row">
                        <Col className="centered-text header-text">
                            <h1 className="white-text max-width-900 margin-auto hero-text">
                                Ensuring Ongoing Success
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text video">
                            <WistiaPopoverVideo id={'1mizkwsacp'}>
                                <div className="video-wrapper">
                                    <img
                                        className="play-button"
                                        src={require('../../../images/vendor-onboarding/play-button.svg')}
                                        alt="play button"
                                    />
                                    <img
                                        className="thumbnail"
                                        src={require('../../../images/vendor-onboarding/ensure-ongoing-success/thumbnail.png')}
                                        alt="Ensuring ongoing success video thumbnail"
                                    />
                                </div>
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row className="introduction-row">
                        <Col className="max-width-900">
                            <p>
                                In this final guide we will cover some of the
                                activities we recommend you engage in to support
                                your partner throughout the campaign and after
                                to maximize the results.
                            </p>
                        </Col>
                    </Row>
                    <Row className="engagement-row">
                        <Col className="max-width-900">
                            <h1 className="title large">Engagement</h1>
                            <p>
                                Of course you’re looking for go-getters to be a
                                part of your program. However, it’s always a
                                good idea to consistently engage with your
                                partner throughout the program to provide
                                additional support and catch any challenges
                                early. We recommend scheduling a 15 minute sync
                                up call at least twice a month to review lead
                                generation and talk about follow up actions.
                                This will help keep the momentum throughout the
                                program and also give you insights directly from
                                the partner that can help you make changes that
                                will provide better outcomes.
                            </p>
                        </Col>
                    </Row>
                    <Row className="sync-up-row max-width-900">
                        <Col className="sync-up-col">
                            <p>
                                Here are some things to cover in your sync ups:
                            </p>
                            <ul>
                                <li>Campaign results </li>
                                <li>
                                    Total leads for that previous week or month{' '}
                                </li>
                                <li>
                                    Total activities (prospect and sale follow
                                    up){' '}
                                </li>
                                <li>Quality of follow ups</li>
                            </ul>
                            <p>
                                Let’s talk a bit about each trait, how they
                                apply, what to look for.
                            </p>
                        </Col>
                    </Row>
                    <Row className="what-to-look-for-row max-width-900">
                        <Col className="three-fifth what-to-look-for-col">
                            <h1 className="title large">
                                What should I look for?
                            </h1>
                            <p>
                                When reviewing the results with your partner you
                                should be looking for a few conversion
                                percentages that should lead your partner to
                                overall better results. Typically, we see an
                                average across the industry of a 3-5% total
                                close from lead to closed opportunity. The first
                                marker to look for is the conversion rate
                                between leads to meetings. On average, a partner
                                should be able to convert 20% of total leads to
                                meetings. The next marker is an average
                                conversion ratio of 50% between meetings to
                                opportunity. Lastly is a 50% conversion from
                                opportunity to closed sale. There are many
                                factors that go into achieving this ratio, but
                                the number one indicator that predicts success
                                is sales activities. On average it takes between
                                8-12 touches to close a sale and the campaign
                                will contribute about 4 of those touches on
                                average leaving the rest to the sales process
                                that partner executes.
                            </p>
                        </Col>
                        <Col className="two-fifth high-five-col">
                            <img
                                className="high-five"
                                src={require('../../../images/vendor-onboarding/ensure-ongoing-success/high-five.png')}
                                alt="high five"
                            />
                        </Col>
                    </Row>
                    <Row className="ensure-consistency-row">
                        <Col className="max-width-900">
                            <p>
                                Once you have established the proper activity
                                counts with your partner, the next piece of the
                                puzzle is to ensure consistency. We recommend no
                                more than one email per week on a global scale
                                which your campaign will execute. However, on a
                                smaller one to one scale between the sales rep
                                and the generated lead, we recommend no more
                                than 2 emails and 2 calls per week. We also
                                advise that if a sales rep can not get a
                                meaningful response or meeting set within 2
                                weeks, they should stop their follow up and wait
                                for that contact to interact with the global
                                campaigns. Once that contact generates a new
                                lead activity you can restart your follow up.
                            </p>
                        </Col>
                    </Row>
                    <Row className="no-excuse-row">
                        <Col className="max-width-900">
                            <h1 className="title large">No excuse attitude</h1>
                            <p>
                                When working with your partners it’s important
                                to listen and gather helpful feedback that can
                                be used in the next campaign to improve results.
                                With that being said, we must also ensure the
                                feedback does not validate little to no sales
                                activity or poor quality follow-up. You are
                                there to support your partner, but in the end
                                their perseverance will be the answer to the
                                problem most of the time.
                            </p>
                        </Col>
                    </Row>
                    <Row className="common-excuses-row">
                        <Col className="max-width-900">
                            <h3 className="title small">Common excuses</h3>
                            <p className="quality-subheader">
                                <strong>Quality of lead is bad:</strong>
                            </p>
                            <ul>
                                <li>
                                    Partner pre-selects prospects that meet a
                                    minimum requirement (location, size, contact
                                    type, vertical)
                                </li>
                                <li>
                                    If they provided their own list, we assume
                                    they have already done some
                                    pre-qualification to that list.
                                </li>
                            </ul>
                            <p className="lead-activity-subheader">
                                <strong>Lead activity isn’t real:</strong>
                            </p>
                            <ul>
                                <li>
                                    Leads are only generated when a prospect
                                    clicks on an email call-to-action, downloads
                                    assets or fills out a form.
                                </li>
                                <li>
                                    GlassHive leverages technology to remove all
                                    robotic or “fake clicks” to ensure only
                                    humans are generating lead activities.
                                </li>
                                <li>
                                    No one tripped and fell over the “learn more
                                    button” on accident.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="for-the-future-row max-width-900">
                        <Col>
                            <h1 className="title large">For the future</h1>
                            <p>
                                GlassHive provides helpful insight into your
                                campaign results that can be used to improve and
                                fine tune your next campaign. Reviewing past
                                campaign click through rate and open rate can
                                tell us what prospects are interested in.
                                GlassHive makes it easy to pull a full report
                                for all of your partners in one window.
                            </p>
                        </Col>
                    </Row>
                    <Row className="keep-information-simple-row">
                        <Col className="max-width-900">
                            <p>
                                In order to keep this information simple to
                                consume and easy to remember, we have split
                                these onboarding topics into a series. You
                                should expect to receive the next topic soon but
                                if you would like to keep reading please click
                                on any of the following links below.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="vendor-onboarding-wrapper max-width-900">
                            <Link
                                to="/vendor-onboarding/expectations"
                                className="vendor-onboarding part"
                            >
                                Part 1: Expectations
                            </Link>
                            <Link
                                to="/vendor-onboarding/content-selection"
                                className="vendor-onboarding part"
                            >
                                Part 2: Program Content selection
                            </Link>
                            <Link
                                to="/vendor-onboarding/selecting-the-right-partner"
                                className="vendor-onboarding part"
                            >
                                Part 3: Proper Partner Selection
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="contact-us-col max-width-900">
                            <p>
                                Please don’t hesitate to{' '}
                                <a
                                    href="mailto:info@glasshive.com"
                                    className="contact-us"
                                >
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
